import './navigation.js';
import './skip-link-focus-fix.js'
(( $ ) => {


	$(document).ready( () => {
    // accessibility/layout settings

    var viewStyle = sessionStorage.getItem('view');

    if (viewStyle == 'admin') {
      $('body').addClass('admin');
      $('#view-admin').addClass('bg-blue-900');
      $('#view-default').addClass('bg-blue-100');
    } else if (viewStyle == 'default') {
      $('#view-default').addClass('bg-blue-900');
      $('#view-admin').addClass('bg-blue-100');
    } else {
      $('#view-default').addClass('bg-blue-900');
      $('#view-admin').addClass('bg-blue-100');
    }
    $('#view-admin').click(function(){
      $('body').addClass('admin');
      $('#view-default').removeClass('bg-blue-900').addClass('bg-blue-100');
      sessionStorage.setItem('view', 'admin');
      $(this).removeClass('bg-blue-100').addClass('bg-blue-900');
    });
    $('#view-default').click(function(){
      $('body').removeClass('admin');
      $('#view-admin').removeClass('bg-blue-900').addClass('bg-blue-100');
      $(this).removeClass('bg-blue-100').addClass('bg-blue-900');
      sessionStorage.setItem('view', 'default');
    });
	});

	/* optional triggers

	$(window).load(() => {

	});

	$(window).resize(() => {

	});

	*/


} )( jQuery );
